import React from 'react';
import combineReducers from './combineReducers';
import {
	reviewsReducer,
	competitorsReducer,
	amountsReducer,
	modalReducer,
	filtersReducer,
} from './reducers';
import { API_URL } from '../services/consts';

export const Store = React.createContext();

const initialState = {
	competitors: {
		all: [],
		cached: [],
		selected: '',
	},
	reviews: {
		all: [],
		query: {},
		currentQuery: '',
		blankQuery: API_URL + '/reviews/?competitor__id=&amount__id=',
		order: {
			field: 'competitor',
			dir: 0,
		},
	},
	amounts: {
		all: [],
		selected: '',
	},
	modal: {
		isOpen: false,
	},
	filters: {
		hide: false,
	},
};

const rootReducer = combineReducers({
	reviews: reviewsReducer,
	competitors: competitorsReducer,
	amounts: amountsReducer,
	modal: modalReducer,
	filters: filtersReducer,
});

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(rootReducer, initialState);
	const value = { state, dispatch };
	return <Store.Provider value={value}>{children}</Store.Provider>;
};
