import React from 'react';
import { headers } from '../services/utils';
import { Store } from '../redux/store';
import { changeOrder } from '../redux/actions';
import { get } from 'axios';
import { API_URL } from '../services/consts';

const DepositHeader = () => {
	const { state, dispatch } = React.useContext(Store);

	const setOrder = async (field, dir) => {
		const filtering =
			'&competitor__id=' +
			state.competitors.selected +
			'&amount__id=' +
			state.amounts.selected;
		let url = '/reviews/?ordering=';
		let prefix = '';
		let normalize = '';
		if (field !== 'competitor' || dir === 0) normalize += ',competitor';
		if (field !== 'amount' || dir === 0) normalize += ',amount';
		if (dir === 1) {
			url += prefix + field;
		}
		if (dir === -1) {
			prefix = '-';
			url += prefix + field;
		}
		url += normalize + filtering;

		const reviews = await get(API_URL + url);
		const new_order = {
			reviews: reviews.data,
			field,
			dir,
		};
		return dispatch(changeOrder(new_order));
	};

	const addOrderIcon = (text, field) => {
		let title = '';
		if (state.reviews.order.field === field) {
			if (state.reviews.order.dir === 1) {
				title = 'Ordine crescente ' + text;
				return (
					<strong
						className='order-link'
						onClick={() => setOrder(field, -1)}
						title={title}>
						{text} <i className='fas fa-long-arrow-alt-up ml-1'></i>
					</strong>
				);
			} else if (state.reviews.order.dir === -1) {
				title = 'Ordine decrescente ' + text;
				return (
					<strong className='order-link' onClick={() => setOrder(field, 0)} title={title}>
						{text} <i className='fas fa-long-arrow-alt-down ml-1'></i>
					</strong>
				);
			} else {
				title = 'Ordine alfabetico Competitor';
				return (
					<strong className='order-link' onClick={() => setOrder(field, 1)} title={title}>
						{text} <i className='fas fa-arrows-alt-v ml-1'></i>
					</strong>
				);
			}
		}
		return (
			<strong className='order-link' onClick={() => setOrder(field, 1)}>
				{text}
			</strong>
		);
	};

	return (
		<div className='row align-items-center text-center mb-2 py-3'>
			{headers.map(({ text, field, classes }, index) => (
				<div className={`text-uppercase h6 ${classes}`} key={index}>
					{addOrderIcon(text, field)}
				</div>
			))}
		</div>
	);
};

export default DepositHeader;
