import React from 'react';
import { Store } from '../redux/store';
import { get } from 'axios';
import { fetchReviews } from '../redux/actions';
import ReviewRow from './ReviewRow';
import ReviewHeader from './ReviewHeader';
import Modal from './Modal';
import { API_URL } from '../services/consts';

const ReviewList = () => {
	const { state, dispatch } = React.useContext(Store);
	const [loaded, setLoaded] = React.useState(false);

	React.useEffect(() => {
		const getReviews = async () => {
			// Retrieving reviews from cached localstorage if saved less than 8 hours ago
			const localReviews = JSON.parse(localStorage.getItem('reviews'));
			if (localReviews) {
				if (new Date().getTime() < localReviews.expiration) {
					setLoaded(true);
					return dispatch(fetchReviews(localReviews.content));
				}
			}

			// Fetching reviews from server if localstorage empty or after expiration
			const url = API_URL + '/reviews/';
			const reviews = await get(url);

			setLoaded(true);

			// Storing inside localstorage the api response
			localStorage.setItem(
				'reviews',
				JSON.stringify({
					content: reviews.data,
					expiration: new Date(new Date().setHours(new Date().getHours() + 8)).getTime(),
				}),
			);

			// Dispatching the action of storing reviews inside memory
			return dispatch(fetchReviews(reviews.data));
		};

		getReviews();
		// eslint-disable-next-line
	}, []);

	// While loading resources show a modal with a spinner
	if (!loaded) {
		return <Modal />;
	}

	// If there are no reviews account matching the filters show an alert
	if (state.reviews.all.length === 0) {
		return (
			<div className='row mt-4'>
				<div className='col-12'>
					<div className='alert alert-danger text-center' role='alert'>
						Nessuna analisi corrisponde ai criteri di ricerca
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='row mt-4'>
			<div className='col-10 offset-1 col-md-12 offset-md-0'>
				<ReviewHeader />
				{state.reviews.all.length &&
					state.reviews.all.map(review => <ReviewRow key={review.id} {...review} />)}
			</div>
		</div>
	);
};

export default ReviewList;
