import React from 'react';
import Select from 'react-select';
import { selectTheme } from '../services/utils';

const FilterSelectBox = ({ filter_class, options, labels, values, onChange }) => {
	return (
		<div className='col-12 col-md-6 mb-2 mb-md-0'>
			<Select
				isMulti
				theme={theme => selectTheme(theme)}
				placeholder={`Seleziona ${filter_class}`}
				options={options}
				getOptionLabel={labels}
				getOptionValue={values}
				onChange={onChange}
			/>
		</div>
	);
};

export default FilterSelectBox;
