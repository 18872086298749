import React from 'react';

const ReviewRow = ({
	id,
	competitor,
	collection_date,
	amount,
	installment_no_insurance,
	tan,
	taeg,
	sip,
	interest_no_insurance,
	interest_cpi,
}) => {
	return (
		<div
			className='row shadow mb-4 py-2 rounded bg-white'
			style={{ position: 'relative' }}>
			<div className='col-12'>
				<div className='row py-2 align-items-center text-center'>
					<div className='col d-flex justify-content-center align-items-center'>
						<figure className='w-100 ie11-img-hack'>
							<img
								src={competitor.logo}
								alt={'Logo ' + competitor.name}
								className='img-fluid logo-img'
							/>
						</figure>
					</div>
					<div className='col d-flex justify-content-center align-items-center'>
						{competitor.name}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{amount.slug}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{installment_no_insurance}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{tan}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{taeg}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{sip}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{interest_no_insurance}
					</div>
					<div className='col border-left d-flex justify-content-center align-items-center'>
						{interest_cpi}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewRow;
