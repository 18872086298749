import React from 'react';

const Modal = () => {
	return (
		<div className='modal'>
			<div className='d-flex justify-content-center align-items-center flex-column h-100 w-100'>
				<div className='text-success h2 mb-5 font-weight-light'>
					Stiamo caricando i dati...
				</div>
				<div
					className='spinner-border text-success font-weight-light'
					style={{ width: '4rem', height: '4rem', borderWidth: '2px' }}
					role='status'>
					<span className='sr-only'>Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Modal;
