import React from 'react';
import { Store } from './redux/store';
import Header from './components/Header';
import Modal from './components/Modal';
import Filters from './components/Filters';
import ReviewList from './components/ReviewList';
import ScrollUpButton from 'react-scroll-up-button';
import Expand from 'react-expand-animated';

const App = () => {
	const { state } = React.useContext(Store);
	return (
		<>
			{state.modal.isOpen && <Modal />}

			<Header />

			<div className='container'>
				<Expand open={!state.filters.hide} duration={400} easing='ease'>
					<Filters />
				</Expand>

				<ReviewList />

				<ScrollUpButton
					ContainerClassName='back-up-container d-none d-md-block'
					TransitionClassName='back-up-transition'>
					<div>
						<a href='#!' className='btn btn-lg rounded-circle btn-outline-success'>
							<i className='fas fa-arrow-up'></i>
						</a>
					</div>
				</ScrollUpButton>
			</div>
		</>
	);
};

export default App;
