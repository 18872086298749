export const headers = [
	{
		text: 'Competitor',
		field: 'competitor',
		classes: 'col',
	},
	{
		text: '',
		field: '',
		classes: 'col',
	},
	{
		text: 'Importo',
		field: 'amount',
		classes: 'col',
	},
	{
		text: 'Rata',
		field: 'installment_no_insurance',
		classes: 'col',
	},
	{
		text: 'TAN',
		field: 'tan',
		classes: 'col',
	},
	{
		text: 'TAEG',
		field: 'taeg',
		classes: 'col',
	},
	{
		text: 'SIP',
		field: 'sip',
		classes: 'col',
	},
	{
		text: 'Int. senza ass.',
		field: 'interest_no_insurance',
		classes: 'col',
	},
	{
		text: 'Int. + CPI',
		field: 'interest_cpi',
		classes: 'col',
	},
];

export const selectTheme = theme => {
	return {
		...theme,
		borderRadius: 3,
		colors: {
			...theme.colors,
			primary: '#28a745',
			primary25: '#89d29a',
		},
	};
};
