import React from 'react';
import { Store } from '../redux/store';
import { toggleFilters } from '../redux/actions';
import { BACK_URL } from '../services/consts';

const Header = () => {
	const { state, dispatch } = React.useContext(Store);

	// Toggle filters section
	const showHideFilters = () => {
		return dispatch(toggleFilters(!state.filters.hide));
	};

	// Delete Cache and retrieve all resources
	const forceUpdate = () => {
		localStorage.removeItem('reviews');
		localStorage.removeItem('competitors');
		localStorage.removeItem('amounts');
		return (window.location.href = '/');
	};

	return (
		<header className='shadow-sm'>
			<nav className='navbar navbar-expand-md navbar-light bg-success'>
				<div className='container'>
					<a className='navbar-brand text-light' href='/'>
						Analisi Mystery
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-toggle='collapse'
						data-target='#navbar-menu'
						aria-controls='navbar-menu'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div className='collapse navbar-collapse justify-content-end' id='navbar-menu'>
						<div className='navbar-nav mt-2 mt-md-0'>
							<a
								className='nav-item shadow btn-danger btn btn-sm p-1 mr-1 mb-1 mb-md-0'
								href='#'
								onClick={showHideFilters}>
								{state.filters.hide ? 'Mostra Filtri' : 'Nascondi Filtri'}
							</a>
							<a
								href={BACK_URL + '/excel'}
								className='nav-item btn-dark btn btn-sm p-1 mr-1 mb-1 mb-md-0'
								download>
								Download Excel
							</a>
							<a
								className='nav-item shadow btn-warning btn btn-sm p-1 mr-1 mb-1 mb-md-0'
								href='#'
								onClick={forceUpdate}>
								Force Update
							</a>
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Header;
