import {
	FETCH_REVIEWS_LIST,
	FETCH_COMPETITORS_LIST,
	FILTER_REVIEW,
	FETCH_AMOUNTS_LIST,
	SELECTED_COMPETITOR_ID,
	SELECTED_AMOUNT_ID,
	TOGGLE_MODAL,
	TOGGLE_FILTERS,
	CHANGE_ORDER,
} from './actionTypes';

import { API_URL } from '../services/consts';

export const reviewsReducer = (reviewState, action) => {
	switch (action.type) {
		case FETCH_REVIEWS_LIST:
			const firstQuery = { ...reviewState.query };
			firstQuery[API_URL + '/reviews/?competitor__id=&amount__id='] = action.payload;
			return {
				...reviewState,
				all: action.payload,
				query: firstQuery,
			};

		case FILTER_REVIEW:
			const query = { ...reviewState.query };
			if (query.hasOwnProperty(action.payload.url)) {
				return { ...reviewState, all: query[action.payload.url] };
			}
			query[action.payload.url] = action.payload.content;
			const all = action.payload.content;
			return { ...reviewState, all, query, currentQuery: action.payload.url };

		case CHANGE_ORDER:
			const { field, dir } = action.payload;

			return { ...reviewState, all: action.payload.reviews, order: { field, dir } };
		default:
			return reviewState;
	}
};

export const competitorsReducer = (competitorState, action) => {
	switch (action.type) {
		case FETCH_COMPETITORS_LIST:
			return { ...competitorState, all: action.payload };
		case SELECTED_COMPETITOR_ID:
			return { ...competitorState, selected: action.payload };
		default:
			return competitorState;
	}
};

export const amountsReducer = (amountState, action) => {
	switch (action.type) {
		case FETCH_AMOUNTS_LIST:
			return { ...amountState, all: action.payload };
		case SELECTED_AMOUNT_ID:
			return { ...amountState, selected: action.payload };
		default:
			return amountState;
	}
};

export const modalReducer = (modalState, action) => {
	switch (action.type) {
		case TOGGLE_MODAL:
			return { ...modalState, isOpen: action.payload };
		default:
			return modalState;
	}
};

export const filtersReducer = (filtersState, action) => {
	switch (action.type) {
		case TOGGLE_FILTERS:
			return { ...filtersState, hide: action.payload };
		default:
			return filtersState;
	}
};
