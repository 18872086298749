import {
	FETCH_REVIEWS_LIST,
	FETCH_COMPETITORS_LIST,
	FILTER_REVIEW,
	FETCH_AMOUNTS_LIST,
	SELECTED_COMPETITOR_ID,
	SELECTED_AMOUNT_ID,
	TOGGLE_MODAL,
	TOGGLE_FILTERS,
	CHANGE_ORDER,
} from './actionTypes';

export const fetchReviews = payload => {
	return { type: FETCH_REVIEWS_LIST, payload };
};

export const filterReview = payload => {
	return { type: FILTER_REVIEW, payload };
};

export const selectCompetitor = payload => {
	return { type: SELECTED_COMPETITOR_ID, payload };
};
export const selectAmount = payload => {
	return { type: SELECTED_AMOUNT_ID, payload };
};

export const fetchCompetitors = payload => {
	return { type: FETCH_COMPETITORS_LIST, payload };
};
export const fetchAmounts = payload => {
	return { type: FETCH_AMOUNTS_LIST, payload };
};

export const toggleModal = payload => {
	return { type: TOGGLE_MODAL, payload };
};

export const toggleFilters = payload => {
	return { type: TOGGLE_FILTERS, payload };
};

export const changeOrder = payload => {
	return { type: CHANGE_ORDER, payload };
};
