import React from 'react';
import {
	fetchCompetitors,
	filterReview,
	fetchAmounts,
	selectCompetitor,
	selectAmount,
	toggleModal,
} from '../redux/actions';
import { Store } from '../redux/store';
import { get } from 'axios';
import FilterSelectBox from './FilterSelectBox';
import { API_URL } from '../services/consts';

const Filter = () => {
	const { state, dispatch } = React.useContext(Store);

	React.useEffect(() => {
		const getCompetitors = async () => {
			const localCompetitors = JSON.parse(localStorage.getItem('competitors'));
			if (localCompetitors) {
				if (new Date().getTime() < localCompetitors.expiration) {
					return dispatch(fetchCompetitors(localCompetitors.content));
				}
			}

			const url = `${API_URL}/competitors/`;
			const competitors = await get(url);

			localStorage.setItem(
				'competitors',
				JSON.stringify({
					content: competitors.data,
					expiration: new Date(new Date().setHours(new Date().getHours() + 8)).getTime(),
				}),
			);

			return dispatch(fetchCompetitors(competitors.data));
		};

		const getAmounts = async () => {
			const localAmounts = JSON.parse(localStorage.getItem('amounts'));
			if (localAmounts) {
				if (new Date().getTime() < localAmounts.expiration) {
					return dispatch(fetchAmounts(localAmounts.content));
				}
			}

			const url = `${API_URL}/amounts/`;
			const amounts = await get(url);

			localStorage.setItem(
				'amounts',
				JSON.stringify({
					content: amounts.data,
					expiration: new Date(new Date().setHours(new Date().getHours() + 8)).getTime(),
				}),
			);

			return dispatch(fetchAmounts(amounts.data));
		};

		getCompetitors();
		getAmounts();
		// eslint-disable-next-line
	}, []);

	const filterOutReviews = async (competitor_ids, amount_ids) => {
		const ordering =
			'&ordering=' +
			(state.reviews.order.dir < 0 ? '-' : '') +
			state.reviews.order.field +
			',competitor,amount';
		const url = `${API_URL}/reviews/?competitor__id=${competitor_ids}&amount__id=${amount_ids}${ordering}`;

		if (state.reviews.query.hasOwnProperty(url)) {
			dispatch(filterReview({ url: url }));
		} else {
			const reviews = await get(url);

			dispatch(filterReview({ url: url, content: reviews.data }));
		}

		document.body.style.overflowY = 'auto';
		return dispatch(toggleModal(false));
	};

	const filterByCompetitor = competitors => {
		modalNoScroll();

		const ids = competitors ? competitors.map(competitor => competitor.id).join(',') : '';

		dispatch(selectCompetitor(ids));

		filterOutReviews(ids, state.amounts.selected);
	};

	const filterByAmounts = amounts => {
		modalNoScroll();

		const ids = amounts ? amounts.map(amount => amount.id).join(',') : '';

		dispatch(selectAmount(ids));

		filterOutReviews(state.competitors.selected, ids);
	};

	const modalNoScroll = () => {
		document.body.style.overflowY = 'hidden';
		dispatch(toggleModal(true));
	};

	return (
		<>
			<div className='row mt-5 mb-4'>
				<FilterSelectBox
					filter_class='Competitor'
					options={state.competitors.all}
					labels={option => option.name}
					values={option => option.id}
					onChange={filterByCompetitor}
				/>
				<FilterSelectBox
					filter_class='Importo'
					options={state.amounts.all}
					labels={option => option.slug}
					values={option => option.id}
					onChange={filterByAmounts}
				/>
			</div>
			<div className='dropdown-divider'></div>
		</>
	);
};

export default Filter;
